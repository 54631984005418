import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import banner from '../assets/img/premium-header.jpg'
import cake from '../assets/img/home-banner.jpg'
import pastry from '../assets/img/pastry.jpg'
import bread from '../assets/img/breads.jpg'
import cookie from '../assets/img/cookieBackground.jpg'
import {
  Accordion, AccordionSummary, AccordionDetails, Button,
  Box, Fade, Grid, withWidth, makeStyles
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import app from '../api/base.js'
import { Header, fonter, PageDivider } from '../components/TableComponents';
import {
  FiCard,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "../components/FullImageCard";
import { getDatabase, ref, onValue } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";



const categories = ['Cakes', 'Pastries', 'Breads', 'Cookies']

export const App = ({freemode}) => {
  const [checked, setChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [recipeList, setRecipeList] = useState([]);
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    accordian: {
      borderRadius: 20,
      marginLeft: 5,
      marginRight: 5
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fef1e8'
    },
    cover: {
      height: 100
    },

    card: {
      borderRadius: 20
    },

    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    fiCardHeader: {
      color: "#ffffff",
      backgroundColor: "rgba(0,0,0,0)",
      textAlign: 'center',
      height: expanded ? 30 : 50,
      width: '100%',
      display: "flex"
    },
    fiCardContent: {
      color: "#ffffff",
      backgroundColor: "rgba(0,0,0,.25)",
      textAlign: 'center',
      height: 70
    },
    fiCardContentTextSecondary: {
      color: "rgba(255,255,255,0.78)"
    }
  }));

  const storage = getStorage(app);
  const db = getDatabase(app);

  useEffect(() => {
    setChecked(true)
    const recipeRef =  ref(db, 'Recipes/');
    onValue(recipeRef, (snapshot) => {
      const recipes = snapshot.val();
      const recipeArray = []
      for (let id in recipes) {
        const item = recipes[id]

        const loadImages = async () => {
          getDownloadURL(storageRef(storage, `Images/${item.Name}.png`))
          .then((url) => {
            item['imageURL'] = url
          })
        }
        loadImages();
        recipeArray.push(item)
      }
      setRecipeList(recipeArray)
    })
  }, [])

  const classes = useStyles();
  const images = [cake, pastry, bread, cookie]


  return (
    <Box pb={10}>
    { !freemode && <Header banner={banner} checked={checked} title='Your Online Baking Class' sub='Unlimited Booking Classes on Demand' />}
      <PageDivider text={freemode ? `A preview of our 50+ classes only available under Premium Subscription` : `Premium Videos`} />
      <Box mx={{ xs: 0, sm: 2, md: 5, lg: 20, xl: 20 }}>
        {[...(new Array(categories.length))].map((_, c) => {
          return (
            <Accordion elevation={0}
              key={c}
              className={classes.accordian}
              expanded={expanded === 'panel' + c}
              onChange={handleChange('panel' + c)}>
              <AccordionSummary>
                {(expanded !== 'panel' + c) &&
                  <FiCardMedia
                    style={{
                      borderTopLeftRadius: c == 0 && 30,
                      borderTopRightRadius: c == 0 && 30,
                      borderBottomLeftRadius: c == categories.length - 1 && 30,
                      borderBottomRightRadius: c == categories.length - 1 && 30,
                    }}
                    media="picture"
                    image={images[c]}
                  />}
                <FiCardContent className={classes.fiCardHeader}>
                  <Box flexGrow={1} style={{ color: (expanded === 'panel' + c) && '#542224' }}>
                    {fonter(categories[c], `h4`, true)}
                    {(expanded !== 'panel' + c) ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </Box>
                </FiCardContent>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  {[...(new Array(recipeList.length))].map((_, i) => {
                    if (recipeList[i].Category == categories[c] && recipeList[i].imageURL) {
                      return (
                        <Grid key={i} item xs={6} sm={6} lg={4}>
                          <Fade in={checked}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(checked ? { timeout: i * 50 } : {})}>
                            <FiCard elevation={10} className={classes.card}>
                              <FiCardMedia
                                media="picture"
                                image={recipeList[i].imageURL}
                              />
                              <FiCardContent className={classes.fiCardContent}>
                                {fonter(recipeList[i].Name, `h6`, true)}

                              </FiCardContent>
                              <FiCardActions className={classes.fiCardContent}>
                               { !freemode && <Button component={Link} to={"/Home/classes/" + recipeList[i].Name.replace(/\s/g, '') + ""} size="small" color="inherit" variant="outlined">
                                  <PlayArrowIcon />
                                </Button>}
                              </FiCardActions>
                            </FiCard>
                          </Fade>
                        </Grid>
                      )
                    }
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Box>
  )
};

App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(App);