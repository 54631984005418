import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from './Auth'
import {
  Hidden, Button, CardContent, Card, Box, Fade, Grow
} from '@material-ui/core';
import { fonter } from '../components/TableComponents';

import banner from '../assets/img/home-banner.jpg'
import app from '../api/base.js'
import { getAuth, signOut } from "firebase/auth";


const EmailError = () =>
  <Box>
    <Fade in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 100 } : {})}>
      <Card square style={{
        backgroundImage: 'url(' + banner + ')',
        backgroundSize: "cover",
        color: "#f5f5f5"
      }}>

        <Grow in={true} style={{ transformOrigin: 'center bottom' }}
          {...(true ? { timeout: { enter: 2000, exit: 3000 } } : {})}>
          <CardContent>
            <Box mt={38} mb={20} textAlign='center'>
              {/* Mobile UI */}
              <Hidden xlUp lgUp>
                {fonter(<h3 style={{ textShadow: '5px 5px 5px  #23383C' }}>Verification</h3>, `h3`, true)}
              </Hidden>
              {/* Desktop UI */}
              <Hidden mdDown smDown xsDown>
                {fonter(<h2 style={{ textShadow: '5px 10px 5px  #23383C' }}>Verification</h2>, `h2`, true)}
              </Hidden>
              {fonter(<h4 style={{ textShadow: '5px 5px 5px  #23383C' }}>Please verify your email to continue
              </h4>, `h4`, true)}
              <Button style={{
                color: "#f5f5f5"
              }} onClick={() => signOut(getAuth())}>
                {fonter(<h4 style={{ textShadow: '5px 5px 5px  #23383C' }}>Logout
                </h4>, `h4`, true)}

              </Button>
            </Box>
          </CardContent>
        </Grow>
      </Card>
    </Fade>
  </Box>


const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!!currentUser) {
          if (!currentUser.emailVerified) {
            return (<EmailError />)
          } else {
            return (children)
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      }
      }
    />
  );
};
export default PrivateRoute;