import React, { useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../api/base.js";
import { sendPasswordResetEmail } from "firebase/auth"
import { AuthContext, uiConfig } from "../components/Auth.js";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import Homepage from '../tables/Homepage'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import { DialogContent, Button, InputAdornment, TextField, Box, Collapse, Toolbar, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import SignUp from '../pages/SignUp'
import { yupResolver } from '@hookform/resolvers/yup';
import { alertbar } from "../components/TableComponents.js";
import { textfieldStyles } from "../components/styles.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";



const Login = ({ history }) => {

  const auth = getAuth(app);
  const classes = textfieldStyles();
  const [alertInfo, setAlertInfo] = useState({ message: "No connection", error: false })
  const [emailConfirm, setEmailConfirm] = useState(false)
  const [pwPage, setPwPage] = useState(false)
  const [regPage, setRegPage] = useState(false)

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(
      pwPage ?
        yup.object().shape({ //Login Form's email and password validation
          email: yup.string().required()
        }) :
        yup.object().shape({ //Reset Password's email validation
          email: yup.string().required(),
          password: yup.string().required()
        }))
  });

  const onSubmit = ({ email, password }) => {
    handleLogin(email, password)
  };

  const onResetEmail = ({ email }) => {
    sendPasswordResetEmail(auth, email).then(() => {
      setEmailConfirm(true)
      setTimeout(() => setEmailConfirm(false), 1500);
    }).catch(function (error) {
      alert(error)
    });

  }



  const handleLogin = async (email, password) => {

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        history.push("/");
      })
      .catch((err) => {
        setAlertInfo({ message: err.message, error: true })
        setTimeout(() => { setAlertInfo({ message: null, error: false }) }, 1500)
      });
  }


  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }




  const Inputs = () =>
    <Box>

      <Collapse in={!regPage}>
        <form onSubmit={pwPage ? handleSubmit(onResetEmail) : handleSubmit(onSubmit)}>
          {alertbar(({ message: alertInfo.error && alertInfo.message, severity: "warning" }), alertInfo.error)}
          <DialogContent style={{ backgroundColor: '#fdf4ed' }}>
            <TextField
              variant="outlined"
              margin="normal"
              {...register('email', { required: true, minLength: 7 })}
              fullwidth
              id="email"
              label="Email"
              type="email"
              autoComplete="email"
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment>
                    <PersonIcon />
                  </InputAdornment>
                )
              }} />

            {alertbar(({ message: errors.email && errors.email.message, severity: "warning" }), errors.email != null)}
            {alertbar(({ message: 'An email to reset your password has been sent, please check your SPAM folder', severity: "success" }), emailConfirm)}
            <Collapse in={!pwPage}>
              <TextField
                variant="outlined"
                margin="normal"
                {...register('password', { required: false, minLength: 7 })}
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <LockIcon />
                    </InputAdornment>
                  )
                }} />
              {alertbar(({ message: errors.password && errors.password.message, severity: "warning" }), errors.password != null)}
              <Toolbar>
                <Button edge='start' size="small" onClick={() => setPwPage(true)}>
                  Forgot Password?
                </Button>
                <div style={{ flexGrow: 1 }} />
                <Button edge='end' size="small" onClick={() => setRegPage(true)}>
                  Not a user?
                </Button>
              </Toolbar>
            </Collapse>
          </DialogContent>

          <Box>
            <Collapse in={!pwPage}>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={auth} />
            </Collapse>

            <Collapse in={pwPage}>
              <Button
                onClick={() => setPwPage(false)}
                style={{ padding: '10px', borderRadius: 20, marginBottom: '10px' }}>
                <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                  Back
                </Box>
              </Button>
            </Collapse>

            <Box p={1}>
              <Button
                type="submit"
                fullWidth
                style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                <Box fontFamily="Poppins" fontWeight="fontWeightBold">
                  {pwPage ? `Reset Password` : ` Log In`}
                </Box>
              </Button>
            </Box>
          </Box>
        </form>
      </Collapse>
      <Collapse in={regPage}>
        <SignUp />
      </Collapse>
    </Box>




  return (
    <div>
      <Homepage
        Login={Inputs()}
        closeRegPage={() => setRegPage(false)}
        regPage={regPage}
        closePwPage={() => setPwPage(false)}
        pwPage={pwPage} />
    </div>

  );
};

export default withRouter(Login);
