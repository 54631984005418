
import React, { Fragment, memo } from "react";
import LoginPage from './pages/Login'
import Home from './pages/Home';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from './components/Auth'

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/Home">
            <Home />
          </PrivateRoute>
          {/* Protects mainpage and API */}
          <Route exact path="/">
            <Redirect exact from="/" to="Home" />
          </Route>
          <Route path="*" exact={false}>
            {/* Exclude paths starting with /api/ but not ending with / */}
            {location.pathname.startsWith('/api/') && !location.pathname.endsWith('/') ? null : (
              <Redirect to="Home" />
            )}
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default App;