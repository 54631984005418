import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import banner from '../assets/img/free-header.jpg'
import { Button, CardContent, Card, CardMedia, Box, Fade, Grid, Paper, Slide, withWidth, makeStyles } from '@material-ui/core';
import { freeClass, PaymentOptions, Header, fonter, PageDivider } from '../components/TableComponents';
import comma from '../assets/img/inverted-commas.png';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import testimonalImage from '../assets/img/what-our-student-say.png';
import app from '../api/base.js'
import { Link } from "react-router-dom";
import {
  FiCard,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "../components/FullImageCard";
import PremiumClasses from './PremiumClasses';
import { getDatabase, ref, onValue } from "firebase/database";


const useStyles = makeStyles({
  root: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    marginTop: 40
  },
  cover: {
    height: 100
  },
  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,.24)",
    textAlign: 'center',
    alignContent: 'center'
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)"
  }
});


const FreeVideoRow = ({ checked, classes, executeScroll }) =>
  <Card elevation={0} className={classes.details}>
    <CardContent >
      <PageDivider text={`Free Videos`} />
    </CardContent>

    <Box sm={2}>
      <Grid container spacing={1}>
        {[...(new Array(3))].map((_, i) => {
          return (
            <Grid key={i} item xs={12} sm={4}>
              <Fade in={checked}
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: (i * 500) + 500 } : {})}>

                <FiCard className={classes.card}>
                  <FiCardMedia
                    media="picture"
                    image={freeClass.free[i]}
                  />
                  <FiCardContent className={classes.fiCardContent}>

                    {fonter(freeClass.cakes[i], `h5`, true)}
                    <Box className={classes.fiCardContentTextSecondary}>
                      {fonter(freeClass.info[i], `body2`, true)}
                    </Box>
                  </FiCardContent>
                  <FiCardActions className={classes.fiCardContent}>
                    <Box display="flex" width='100%' flexDirection="row-reverse">
                      {i != 1 ? <Button component={Link} to={"/Home/classes/" + freeClass.cakes[i] + ""} size="small" color="inherit" variant="outlined">
                        <PlayArrowIcon />
                      </Button> : <Button onClick={executeScroll} size="small" color="inherit" variant="outlined">
                        <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                          Cakes Premium Video
                        </Box>
                      </Button>}
                    </Box>
                  </FiCardActions>
                </FiCard>
              </Fade>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  </Card >


const Testimonials = ({ animeRef, animeState, classes }) =>
  <Fade in={animeState}
    style={{ transformOrigin: '0 0 0' }}
    {...(animeState ? { timeout: 5000 } : {})}>
    <Paper ref={animeRef} elevation={0} style={{ paddingBottom: 100 }} justify="center">
      <Grid container justifyContent="center" style={{ padding: '10px' }}>
        <PageDivider text={`What Our Students Say`} />
      </Grid>
      <Box mx={{ xs: 0, sm: 2, md: 10, lg: 20, xl: 50 }}>

        <Card elevation={0} className={classes.root} >
          <Grid container direction="row"
          justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={12} sm={4}>
              <Slide direction="right" in={animeState} {...(animeState ? { timeout: 4000 } : {})} mountOnEnter unmountOnExit>
                <CardMedia
                  component="img"
                  image={testimonalImage}
                />
              </Slide>
            </Grid>
            <Grid item xs={12} sm={8} >

              <CardContent  >
                <Card elevation={0}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={1}>
                        <img src={comma} />
                      </Grid>
                      <Grid item xs />
                    </Grid>
                    <Box>
                      {fonter(`Sign up with Chef Suriani’s class`, `h4`, true)}
                      {fonter(`If you have the means, time and interest..pls sign up with Chef Suriani’s class. You will not regret it. You will be able to make the same cake at home. I’ve been to her classes.`, `body1`, false)}
                      <div style={{ color: '#542224' }}>{fonter(`Ms Hunny Bunny`, `body1`, true)}</div>
                    </Box>
                  </CardContent>
                </Card>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Paper>
  </Fade>

const App = () => {
  const [checked, setChecked] = React.useState(false);
  const [paypalKey, setPaypalKey] = useState();
  const [paymentModes, setPaymentModes] = useState();
  const db = getDatabase(app);

  useEffect(() => {
    setChecked(true)
    const paymentRef = ref(db, 'PaymentModes');
    onValue(paymentRef, (snapshot) => {
      const mode = snapshot.val();
      const modeArray = []
      for (let id in mode) {
        (id == 'paypalKey') ? setPaypalKey(mode[id]) : modeArray.push(mode[id]);
      }
      setPaymentModes(modeArray)
    })
  }, [])

  const classes = useStyles();
  const [anime1State, setAnime1State] = useState(false)
  const [anime2State, setAnime2State] = useState(false)
  const animeRefs = [...(new Array(3))].map(x => useRef(null));

  const onScroll = () => {
    const scrollWindow = window.scrollY + window.innerHeight
    const top = (i) => animeRefs[i].current.getBoundingClientRect().top
    { (top(0) < scrollWindow) && setAnime1State(true) }
    { (top(1) < scrollWindow) && setAnime2State(true) }
  }

  useLayoutEffect(() => {

    window.addEventListener(`scroll`, onScroll)
    return () => window.removeEventListener(`scroll`, onScroll)

  }, [])

  return (
    <Box pb={10}>
      <Header banner={banner} checked={checked} title='Your Online Baking Class' sub='Unlimited Booking Classes on Demand' />
      <Box mx={{ xs: 0, sm: 2, md: 10, lg: 20, xl: 40 }}>

        <FreeVideoRow
          checked={checked}
          classes={classes}
          executeScroll={() => animeRefs[0].current.scrollIntoView()} />
      </Box>
      <PremiumClasses freemode={true}/>

      {paymentModes ?
        <PaymentOptions
          animeRef={animeRefs[0]}
          animeState={anime1State}
          paypalKey={paypalKey}
          paymentModes={paymentModes} /> :
        <div ref={animeRefs[0]}>Loading</div>

      }
      <Testimonials
        animeRef={animeRefs[1]}
        animeState={anime2State}
        classes={classes} />
    </Box>
  )
};

App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(App);