import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const textfieldStyles = makeStyles(() => ({
  root: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#542224"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#542224"
    }
  },
  cssLabel: {
    color: "#542224",
    fontFamily: `Poppins`,
    "&.Mui-focused": {
      fontFamily: `Poppins`,
      color: "#542224"
    }
  },
  input: {
    fontFamily: `Poppins`,
    borderRadius: 20,
    backgroundColor: `white`,
    color: "#542224",

  }
}));

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#00000',
    },
  },
})(Tabs);

export const StyledHomeTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(17),
    textTransform: 'uppercase',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#83d7da',
      opacity: 1,
    },
    '&$selected': {
      color: '#83d7da'
    },
    '&:focus': {
      color: '#83d7da',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(17),
    textTransform: 'uppercase',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#83d7da ',
      opacity: 1,
    },
    '&$selected': {
      color: '#83d7da'
    },
    '&:focus': {
      color: '#v',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
