import React from "react";
import { withRouter } from "react-router";
import { Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Button, Box, Typography } from '@material-ui/core';

const TermsNConditions = ({open, handleClose}) => {
    const Content = () =>
        <div>
            <p>Last updated: December 17, 2020</p>
            <p>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the http://scgodigital.com/ website (the “Service”) operated by Sisterscookies Pte Ltd (“us”, “we”, or “our”).</p>
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
            <p>&nbsp;</p>
            <h4><strong>Purchases</strong></h4>
            <h4>&nbsp;</h4>
            <h4><strong>Availability, Errors and Inaccuracies</strong></h4>
            <p>We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites. You expressly agree that any such offer of a product or service does not constitute a legal offer capable of attracting legal consequences.</p>
            <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice. Section “Availability, Errors and Inaccuracies” is without prejudice to existing statutory rights.</p>
            <p>&nbsp;</p>
            <h4><strong>Accounts</strong></h4>
            <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
            <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You agree not to disclose your password to any third party.</p>
            <p>You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene. You expressly agree that we cannot be held liable for any loss or damage arising out of any misrepresentations you make in this regard.</p>
            <p>&nbsp;</p>
            <h4><strong>Intellectual Property</strong></h4>
            <p>The Service and its original content, features and functionality are and will remain the exclusive property of Sisterscookies Pte Ltd and its licensors. The Service is protected by copyright, trademark, and other laws of both the Singapore and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Sisterscookies Pte Ltd.</p>
            <p>&nbsp;</p>
            <h3><strong><span style={{color: '#993300'}}>Links To Other Web Sites</span></strong></h3>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Sisterscookies Pte Ltd.</p>
            <p>Sisterscookies Pte Ltd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Sisterscookies Pte Ltd shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
            <p>&nbsp;</p>
            <h4><strong>Termination</strong></h4>
            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
            <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
            <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            <p>Sisterscookies Pte Ltd reserves the right to refuse sale of subscriptions to users, or suspend or cancel subscriptions of users found to be misusing content or breaching these Terms &amp; Conditions.</p>
            <p>&nbsp;</p>
            <h4><strong>Governing Law</strong></h4>
            <p>These Terms shall be governed and construed in accordance with the laws of Singapore, without regard to its conflict of law provisions.</p>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
            <p>&nbsp;</p>
            <h4><strong>Changes</strong></h4>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you must stop using the service.</p>
            <p>&nbsp;</p>
            <h4>
                <strong><span style={{fontSize:'100%'}}>Monthly and Annual Subscription Terms and Fees</span></strong></h4>
            <p>Online Classes are accessed only by subscription. We only offer monthly or yearly payment plans. These plans cannot be altered or changed in any way. Refer to the ‘Online Classes” Tab for our current pricing. Sisterscookies Pte Ltd reserves the right to change the subscription fee and will provide a minimum of 30 days’ notice for any changes in fees to current subscribers.</p>
            <p>This is an opt-out service. This means that money will be debited from your credit card or PayPal account as per your subscription – either monthly or yearly. You must cancel your subscription in order to stop the automatic payments.</p>
            <p>Monthly subscriptions are charged every month from the date you first subscribed.</p>
            <p>Annual subscriptions are charged every year on the anniversary from the date you first subscribed.</p>
            <p>&nbsp;</p>
            <h4><strong>Subscription Changes and Cancellations</strong></h4>
            <p>Your online subscription can be cancelled at any point. Go to your account page, click ‘subscriptions’ and press ‘cancel’. This cancels the subscription immediately and you will no longer be able to access the SC Go Digital members page or any of the online video courses.</p>
            <p>This may take up to 24 – 48 hours (window cancellation period) from the moment you submit this request before your account will be affected. If your bank account is debited during the ‘cancellation window period, please reach out to us at “Contact US” tab for a refund of the amount which was automatically debited.</p>
            <p>Cancelling your recurring PayPal payment through PayPal directly will result in an immediate cancellation of your subscription. This means that if you cancel your subscription during a cycle, you will forfeit any days remaining and no longer have access to the online classes. No pro-rata refund will be issued.</p>
            <p>If you cancel your subscription during the month or year, you will not be refunded for the remaining days left on your subscription. If you decide to cancel your subscription during your cycle, you will forfeit any days remaining and will not be able to resume that subscription. No pro-rata refund will be issued for this service. You will need to re-subscribe in order to renew your online subscription.</p>
            <p>If you request to cancel your subscription after the payment date, you will not be refunded the amount deducted from your account, unless you requested to cancel your subscription before the payment date. Subscriptions may also not be paused or put on hold</p>
            <p>If you have any questions about these Terms, please contact us <a href="https://scgodigital.com/contact-us/">here</a></p>
        </div>

    return (
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Terms & Conditions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Content/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>

    );
};

export default withRouter(TermsNConditions);