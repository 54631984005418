import React, { useEffect, useState } from 'react';
import app from "../api/base.js"
import { getAuth, onAuthStateChanged, GoogleAuthProvider } from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { Backdrop } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
export const AuthContext = React.createContext();

const auth = getAuth(app)

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {

    onAuthStateChanged(auth, firebaseUser => {
      setCurrentUser(firebaseUser)
      setPending(false)
    })
  }, []);

  return (
    pending ? <Backdrop open={true} style={{backgroundColor: '#542224', color: 'white'}}>
    <Skeleton variant="text" animation="wave"/>
  </Backdrop> :
      <AuthContext.Provider
        value={{
          currentUser
        }}
      >
        {children}
      </AuthContext.Provider>
  );
};