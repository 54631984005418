import React, { useState } from "react";
import { withRouter } from "react-router";
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import { DialogActions, DialogContent, Button, InputAdornment, Grid, TextField, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { alertbar } from "../components/TableComponents.js";
import { textfieldStyles } from "../components/styles";
import emailjs from '@emailjs/browser'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile  } from "firebase/auth";

const LoginSchema = yup.object().shape({
  email: yup.string().required(),
  confirmEmail: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required()
});


const SignUp = ({ history }) => {
  const [alertInfo, setAlertInfo] = useState({ message: "No connection", error: false })

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(LoginSchema)
  });

  const classes = textfieldStyles();

  const onSubmit = ({ email, confirmEmail, password, confirmPassword, firstName, lastName, remember }) => {

    if (confirmEmail != email) {
      setAlertInfo({ message: "Email doesn't match", error: true })
      setTimeout(() => { setAlertInfo({ message: null, error: false }) }, 1500)
    }

    if (confirmPassword != password) {
      setAlertInfo({ message: "Password doesn't match", error: true })
      setTimeout(() => { setAlertInfo({ message: null, error: false }) }, 1500)
    }

    else {
      const name = `${firstName} ${lastName}`
      var templateParams = {
        name: name,
        email: email,
        date: new Date().toLocaleString()
      };



      emailjs.send('service_k2vsz18', 'template_n09b7u9', templateParams, 'user_zKZrUq4Tl3ZPsEHvU6HFg')
        .then((result) => {
          handleSignUp(email, password, name);
        }, (error) => {
          console.log(error.text);
        });



    }
  };


  const handleSignUp = async (email, password, name) => {
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {

      updateProfile(auth.currentUser, {
        displayName: name
      })
      sendEmailVerification(userCredential.user).then(() => {
        alert("Email verification sent")
      }).catch(() =>{
        alert("Email verification not sent")
      });
      


    })

    .catch((err) =>  {
      setAlertInfo({ message: err.message, error: true })
      setTimeout(() => { setAlertInfo({ message: null, error: false }) }, 1500)
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {alertbar(({ message: alertInfo.error && alertInfo.message, severity: "warning" }), alertInfo.error)}
      <DialogContent style={{ backgroundColor: '#fdf4ed' }}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              {...register('firstName', { required: true })}
              fullWidth
              id="firstName"
              label="First Name"
              type="text"
              autoFocus
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <PersonIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.firstName && errors.firstName.message, severity: "warning" }), errors.firstName)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              {...register('lastName', { required: true })}
              fullWidth
              id="lastName"
              label="Last Name"
              type="text"
              autoFocus
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <PersonIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.lastName && errors.lastName.message, severity: "warning" }), errors.lastName)}

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              {...register('email', { required: true, minLength: 7 })}
              fullWidth
              name="email"
              label="Email"
              type="email"
              autoComplete="email"
              autoFocus
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <EmailIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.email && errors.email.message, severity: "warning" }), errors.email)}

          </Grid>
          <Grid item xs={12} sm={6}>

            <TextField
              variant="outlined"
              margin="normal"
              {...register('confirmEmail', { required: true, minLength: 7 })}
              fullWidth
              name="confirmEmail"
              label="Confirm Email"
              type="email"
              autoComplete="email"
              autoFocus
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <EmailIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.confirmEmail && errors.confirmEmail.message, severity: "warning" }), errors.confirmEmail)}

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              {...register('password', { required: true, minLength: 7 })}
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <LockIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.password && errors.password.message, severity: "warning" }), errors.password)}

          </Grid>
          <Grid item xs={12} sm={6}>

            <TextField
              variant="outlined"
              margin="normal"
              {...register('confirmPassword', { required: true, minLength: 7 })}
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <LockIcon />
                  </InputAdornment>
                )
              }} />
            {alertbar(({ message: errors.confirmPassword && errors.confirmPassword.message, severity: "warning" }), errors.confirmPassword)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          fullWidth
          style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
          <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
            Sign Up
          </Box>
        </Button>
      </DialogActions>
    </form>
  );
};

export default withRouter(SignUp);