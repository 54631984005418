import React, { useEffect, useState } from "react";
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import about1 from '../assets/img/about1.png'
import about2 from '../assets/img/about2.png'
import about3 from '../assets/img/about3.png'
import about4 from '../assets/img/about4.png'
import Raudhah from '../assets/img/Chef-Raudhah.jpg'
import valueSide from '../assets/img/valueSide.png'
import { Dialog, Slide, AppBar, Toolbar, IconButton, makeStyles, Grid, Box, Fade, Card, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Header, fonter, PageDivider } from "../components/TableComponents";
import banner from '../assets/img/about-us-banner.png'
import {
    FiCard,
    FiCardContent,
    FiCardMedia
} from "../components/FullImageCard";

const infoCards =
    [{
        label: 'Bread Certification Program',
        body: `We are pleased & honoured to have Minister Mr Yaacob Ibrahim & CEO of Mendaki, Mdm Rahayu Buang  to have them participate in our Bread Certification Program for an extremely meaningful event.`,
        media: about1
    },
    {
        label: 'Mendaki’s Project Positif',
        body: `We empower women to supplement their household income via teaching them preeminent baking skills.`,
        media: about4
    },
    {
        label: 'Online Classes',
        body: `Join our online classes to learn the true art of baking. Our classes are beneficial for both beginners and experienced people.`,
        media: about2
    },
    {
        label: 'Hands-on-classes',
        body: `In case you want to learn baking by physically visiting our academy, you are more than welcome. Join us to learn and try the most delicious recipes yourself.`,
        media: about3
    }
    ]

const aboutCards =
    [{
        label: `Inspire, Create and Educate`,
        body: `At SistersCookies, we love baking secrets of professionals so much that we want to share them in our workshops. Be it about processes, timing, ingredients and supplies, we share them all to many eager beginners, intermediates, advance and also professional bakers. \n We also formulated these secrets into simple professional quality recipe that they immediately make you dream of baking up a baking business.`,
        media: Raudhah
    },
    {
        label: `SisterCookies Opens`,
        body: `When you bake with love, it is fun. To us at Sisters Cookies, when you mix that love with the baking secrets, you will say, “I Bake Like a Pro.” \n Baking can seem a lot difficult until you dive into the process yourself. But, once you get along, things seem doable. We know that you can not become a baking expert in your first attempt. Instead, you are bound to make mistakes. But, with the help of the most experienced baking chefs and the hard work and passion that you put in, you can readily master this art.`,
        media: valueSide
    },
    {
        label: `A leader in educating bakers since 2008.`,
        body: `At SistersCookies, we love baking secrets of professionals so much that we want to share them in our workshops. Be it about processes, timing, ingredients and supplies, we share them all to many eager beginners, intermediates, advance and also professional bakers. We also formulated these secrets into simple professional quality recipe that they immediately make you dream of baking up a baking business.\nWhen you bake with love, it is fun. And to us at Sisters Cookies, when you mix that with baking secrets? You will surely say “I Bake like a Pro.”`,
        media: null
    }

    ]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    infoRow: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#fef1e8',
        borderRadius: 20
    },
    appBar: {
        position: 'fixed',
        backgroundColor: 'transparent',
        justifyContent: 'space-between'
    },
    grow: {
        flexGrow: 1,
    },
    icon: {
        color: 'white',
    },
    fiCardContent: {
        color: "#ffffff",
        backgroundColor: "rgba(0,0,0,.24)",
        textAlign: 'center',
        alignContent: 'center'
    },
    fiCardContentTextSecondary: {
        color: "rgba(255,255,255,0.78)"
    }
}));

const AboutUs = ({ open, handleClose }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(true)
    }, [])

    const InfoRow = () =>
        <Box my={5}>
            <Grid container direction='row' spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        {[...(new Array(2))].map((_, i) => {
                            return (
                                <Grid key={i} item>
                                    <Fade in={checked}
                                        style={{ transformOrigin: '0 0 0' }}
                                        {...(checked ? { timeout: (i * 500) + 500 } : {})}>
                                        <Card elevation={8} className={classes.infoRow}>
                                            <Toolbar>
                                                <img src={infoCards[i].media} width={40} />
                                                <Box p={3}>
                                                    <Box style={{ color: '#6c2827' }}>{fonter(infoCards[i].label, `h6`, true)}</Box>
                                                    {fonter(infoCards[i].body, `body1`, true)}</Box>
                                            </Toolbar>
                                        </Card>
                                    </Fade>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>

                    <Grid container direction="column" spacing={2}>
                        {[...(new Array(4))].map((_, i) => {
                            if (i > 1) {
                                return (
                                    <Grid key={i} item>
                                        <Fade in={checked}
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...(checked ? { timeout: (i * 500) + 500 } : {})}>
                                            <Card className={classes.infoRow} elevation={8}>
                                                <Toolbar>
                                                    <img src={infoCards[i].media} width={40} />
                                                    <Box p={3}>
                                                        <Box style={{ color: '#6c2827' }}>{fonter(infoCards[i].label, `h6`, true)}</Box>
                                                        {fonter(infoCards[i].body, `body1`, true)}</Box>
                                                </Toolbar>
                                            </Card>
                                        </Fade>
                                    </Grid>
                                )
                            }

                        })}
                    </Grid>
                </Grid>

            </Grid>

        </Box>

    const AboutRow = () =>
        <Box pt={5} mx={1} mb={10}>
            <PageDivider text={`About SisterCookies`} />

            <FiCard className={classes.infoRow} elevation={0}>
                <FiCardMedia
                    media="picture"
                    image={aboutCards[1].media}
                />
                <FiCardContent className={classes.fiCardContent}>
                    <Box p={{ xs: 0, sm: 0, md: 8, lg: 8 }}>
                        <Box style={{ color: '#FFD7BA' }}>{fonter(<h4 style={{ textShadow: '5px 5px 5px  #6c2827' }}>{aboutCards[0].label}</h4>, `h4`, true)}</Box>
                        {fonter(<p style={{ textShadow: '3px 3px 2px #6c2827' }}>{aboutCards[0].body}</p>, `body1`, true)}
                    </Box>

                    <Box p={{ xs: 0, sm: 0, md: 8, lg: 8 }}>
                        <Box style={{ color: '#FFD7BA' }}>{fonter(<h4 style={{ textShadow: '5px 5px 5px  #6c2827' }}>{aboutCards[1].label}</h4>, `h4`, true)}</Box>
                        {fonter(<p style={{ textShadow: '3px 3px 2px #6c2827' }}>{aboutCards[1].body}</p>, `body1`, true)}
                    </Box>
                </FiCardContent>
            </FiCard>
        </Box>


    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            scroll={'body'}
            TransitionComponent={Transition}
        >
            <Header banner={banner} checked={true} title='About Us' />
            <AppBar elevation={0} className={classes.appBar}>
                <Toolbar>
                    <div className={classes.grow} />
                    <IconButton edge="end" className={classes.icon} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box mx={{ sm: 2, md: 20, lg: 30, xl: 50 }} textAlign='center' alignContent='center'>
                <InfoRow />
                <AboutRow />
                <Box mx={2} mb={20} p={{ xs: 0, sm: 0, md: 8, lg: 8 }}>
                    {fonter(aboutCards[2].label, `h4`, true)}
                    <br />
                    {fonter(aboutCards[2].body, `body1`, true)}
                </Box>
                <Box mb={20} >
                    <Button variant='outlined' size='large' onClick={handleClose}>Close Window</Button>
                </Box>

            </Box>
        </Dialog>

    );
};


AboutUs.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(AboutUs);