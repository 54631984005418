import ReactDOM from 'react-dom';
//css stuff
import * as serviceWorker from './serviceWorker';
import App from './App'
import React from 'react'

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();


