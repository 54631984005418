import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { alertbar, Footer } from '../components/TableComponents'
import { Link, Route, BrowserRouter, Switch } from "react-router-dom";
import { StyledTabs, StyledTab } from '../components/styles'
import logo from '../assets/img/sisterscookies.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Hidden, Toolbar, AppBar,IconButton, Box } from '@material-ui/core';
import ContactUs from '../tables/ContactUs'
import FreeClasses from '../tables/FreeClasses'
import PremiumClasses from '../tables/PremiumClasses'
import Homepage from '../tables/Homepage'
import RecipeInfo from '../tables/RecipeInfo';
import Admin from '../tables/Admin'
import app from "../api/base.js";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import { getDatabase, ref, onValue } from "firebase/database" 
const page = ["/Home/freeClasses", "/Home/premiumClasses", "/Home/admin", "/Home/contactUs"]


const Routes = ({ location, recipeList }) =>
  <Switch>
    {/* Users */}
    <Route key="Homepage" exact path="/Home/">
      <Homepage location={location} />
    </Route>
    <Route key="FreeClasses" path="/Home/freeClasses">
      <FreeClasses location={location} />
    </Route>
    <Route key="PremiumClasses" path="/Home/premiumClasses">
      <PremiumClasses location={location} />
    </Route>
    <Route key="ContactUs" path="/Home/contactUs">
      <ContactUs location={location} />
    </Route>
    {/* Admin */}
    <Route key="Admin" path="/Home/admin">
      <Admin location={location} />
    </Route>
    {recipeList != null && [...(new Array(recipeList.length))].map((_, i) => {
      return (
        <Route key={recipeList[i].Name} path={"/Home/classes/" + recipeList[i].Name.replace(/\s/g, '') + ""}>
          <RecipeInfo name={recipeList[i].Name} link={recipeList[i].Link} />
        </Route>
      )
    })}
  </Switch>

const tabLabel = (text) => <Box component="div" display="inline" fontFamily="Poppins" fontWeight='h6'  >
  {text}
</Box>

const Navigator = ({ value, handleChange, role, homeValue }) => {

  return (
    <AppBar elevation={0} position='absolute' color='transparent'>
      <Box mx={{ xs: 0, sm: 0, md: 10, lg: 20, xl: 50 }}>

        {/* Mobile UI */}
        <Hidden xlUp lgUp>
          <Toolbar>
            <IconButton aria-label="Home" onClick={homeValue} component={Link} to="/Home">
              <img alt="logo" src={logo} height="100" />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <IconButton edge='end' aria-label="delete" onClick={() => signOut(getAuth())}>
              <ExitToAppIcon style={{ fill: "white" }} />
            </IconButton>
          </Toolbar>
        </Hidden>



        {/* Desktop UI */}
        <Box display="flex" alignItems='center' justifyContent='center' >
          <Hidden mdDown smDown xsDown>
            <Box flexGrow={1}>
              <IconButton aria-label="Home" onClick={homeValue} component={Link} to="/Home">
                <img alt="logo" src={logo} height="200" />
              </IconButton>
            </Box>
          </Hidden>
          <StyledTabs variant="scrollable" scrollButtons="on" value={value} onChange={handleChange}>
            {(role === 'premium' || role === 'admin') && <div style={{ borderTopLeftRadius: role === 'premium' && 20, borderBottomLeftRadius: role === 'premium' && 20, marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}><StyledTab component={Link} label={tabLabel('Premium')} to={page[1]} /></div>}
            {role === 'admin' && <div style={{ marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}><StyledTab component={Link} label={tabLabel('Admin')} to={page[2]} /></div>}
            <div style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}><StyledTab component={Link} label={tabLabel('Contact Us')} to={page[3]} /></div>
          </StyledTabs>
          <Hidden mdDown smDown xsDown>
            <IconButton
              onClick={() => signOut(getAuth())}>
              <ExitToAppIcon style={{ fill: "white" }} />
            </IconButton>
          </Hidden>
        </Box>
      </Box>
    </AppBar>
  )
}

function TabPanel(props) {
  const { children, window, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Box>
            {children}
          </Box>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function MainPage({ location }) {
  const [value, setValue] = useState(0);
  const [connection, setConnection] = useState(false)
  const [alertInfo, setAlertInfo] = useState({ message: "No connection", severity: "warning" })
  const [role, setRole] = useState('')
  const [recipeList, setRecipeList] = useState();
  window.addEventListener('online', () => { setAlertInfo({ message: "Connection is up! Reloading...", severity: "success" }); setConnection(false) });
  window.addEventListener('offline', () => { setConnection(true) });
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const [user] = useAuthState(getAuth())


  useEffect(() => {

    user.getIdTokenResult()
      .then(token => token.claims.type)
      .then(type => {
        setRole(type)
        switch (type) {
          case 'admin':
            const admin = page.indexOf(location.pathname)
            admin == -1 ? setValue(false) : setValue(admin)
          case 'premium':
            const premium = [page[1], page[3]].indexOf(location.pathname)
            premium == -1 ? setValue(false) : setValue(premium)
          default:
            const free = [page[0], page[3]].indexOf(location.pathname)
            free == -1 ? setValue(false) : setValue(free)
        }
      })
    const db = getDatabase(app);
    const recipeRef = ref(db, 'Recipes');
      onValue(recipeRef, (snapshot) => {
        const recipes = snapshot.val();
        const recipeArray = []
        for (let id in recipes) {
          recipeArray.push(recipes[id]);
        }
        setRecipeList(recipeArray)
      })
    }, [])


    
    return (
      <BrowserRouter>
        <Navigator value={value} handleChange={handleChange} role={role} homeValue={() => setValue(false)} />
        {alertbar(alertInfo, connection)}
        <Routes recipeList={recipeList} location={location}/>
        <Footer loggedIn={true} logoutButton={() => signOut(getAuth())} />
      </BrowserRouter>
    );
  }



export default withRouter(MainPage);


  // col-sm-* - 576px
  // col-md-* - 768px
  // col-lg-* - 992px
  // col-xl-* - 1200px

