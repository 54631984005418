import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import banner from '../assets/img/home-banner.jpg'
import msSection from '../assets/img/Master-Baking-Section.png'
import msDetails from '../assets/img/Master-Baking-01.png'
import videoImage from '../assets/img/Video.png'
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { Parallax } from 'react-parallax';
import ReactPlayer from 'react-player'
import PremiumClasses from './PremiumClasses';
import {
  Hidden, Toolbar, AppBar, Dialog, DialogContentText, Grid, Button, IconButton, Icon, CardContent, CardMedia, Card, Box,
  Fade, Grow, makeStyles, Collapse, CardActionArea
} from '@material-ui/core';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import SignUp from '../pages/SignUp'
import ContactUs from '../tables/ContactUs'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Testimonials, Chefs, Promo, PaymentOptions, msDetailRows, ResponsivePlayer, Footer, fonter, InfoRow } from '../components/TableComponents';
import app from '../api/base.js'
import logo from '../assets/img/sisterscookies.png';
import { StyledTabs, StyledHomeTab } from '../components/styles'
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import {
  FiCard,
  FiCardContent,
  FiCardMedia
} from "../components/FullImageCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cover: {
    width: 151,
  },
  msSection: {
    height: 490
  },
  msDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    borderRadius: 20
  },

  largeButton: {
    padding: 24,
    color: 'white'
  },
  largeIcon: {
    fontSize: "3em"
  },
  chefs: {
    width: 305,
    backgroundColor: '#fdf4ed',
    borderRadius: 30
  },
  media: {
    height: 300
  }
}));

const Header = ({ checked, title, Tabs, user }) =>
  <Fade in={checked}
    style={{ transformOrigin: '0 0 0' }}
    {...(checked ? { timeout: 100 } : {})}>
    <Card square style={{
      backgroundImage: 'url(' + banner + ')',
      backgroundSize: "cover",
      color: "#f5f5f5"
    }}>

      <Grow in={checked} style={{ transformOrigin: 'center bottom' }}
        {...(checked ? { timeout: { enter: 2000, exit: 3000 } } : {})}>
        <CardContent>
          <Box mt={38} mb={20} textAlign='center'>
            {/* Mobile UI */}
            <Hidden xlUp lgUp>
              {fonter(<h3 style={{ textShadow: '5px 5px 5px  #23383C' }}>{title}</h3>, `h3`, true)}
            </Hidden>
            {/* Desktop UI */}
            <Hidden mdDown smDown xsDown>
              {fonter(<h2 style={{ textShadow: '5px 10px 5px  #23383C' }}>{title}</h2>, `h2`, true)}
            </Hidden>

            {fonter(<h4 style={{ textShadow: '5px 5px 5px  #23383C' }}>Unlimited Booking Classes on Demand</h4>, `h4`, true)}
          </Box>
        </CardContent>
      </Grow>
    </Card>
  </Fade>

const MasterBakingRow = ({ animeRefs, animeState, classes }) =>

  <Card className={classes.root}>
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Fade in={animeState}
          style={{ transformOrigin: '0 0 0' }}
          {...(animeState ? { timeout: 3000 } : {})}>
          <CardMedia
            component="img"
            style={{ height: (animeRefs[1].current != null) && animeRefs[1].current.getBoundingClientRect().height }}
            image={msSection}
          />
        </Fade>
      </Grid>
      <Fade in={animeState}
        style={{ transformOrigin: '0 0 0' }}
        {...(animeState ? { timeout: 5000 } : {})}>
        <Grid item xs={12} sm={7} className={classes.msDetails}>
          <FiCard ref={animeRefs[1]} elevation={0}>
            <FiCardMedia
              media="picture"
              image={msDetails}
            />
            <FiCardContent>
              <Box p={{ xs: 0, sm: 0, md: 8, lg: 8 }}>
                <Box mb={3} style={{ color: '#542224' }}>{fonter(`Master Baking Instantly!`, `h4`, true)}</Box>
                {fonter(`Start making money from what you learn in less than 10 days`, `subtitle1`, true)}
                <br />

                {[...(new Array(4))].map((_, i) => {
                  return (
                    <div key={msDetailRows[i].label}>
                      <Grid container wrap="nowrap" spacing={1}>
                        <Grid item>
                          <Icon>
                            <img alt={`icons${i}`} src={msDetailRows[i].media} height={30} width={30} />
                          </Icon>
                        </Grid>
                        <Grid item xs>
                          {fonter(msDetailRows[i].label, `body1`, true)}
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
                <br />
                <Button
                  onClick={() => animeRefs[0].current.scrollIntoView()}
                  style={{ padding: '10px', borderRadius: 20, backgroundColor: '#542234', color: 'white' }}>
                  <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                    Sign Up Now
                  </Box>

                </Button>
              </Box>
            </FiCardContent>
          </FiCard>
        </Grid>
      </Fade>
    </Grid>

  </Card>

const AboutFounder = ({ executeVideo, classes, playVid, pauseVid, introURL }) =>
  <div>
    <Collapse in={!playVid}>
      <Parallax bgImage={videoImage} bgImageStyle={{ height: window.innerHeight }} strength={window.innerHeight} >
        <div style={{ height: window.innerHeight }}>
          <Grid container
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: "100vh" }}>

            <Grid item xs='auto'>
              <Box
                color='white'
                textAlign='center'
                justifyContent="center"
                alignItems="center"
                mx={{ xs: 5, sm: 5, md: 20, lg: 40 }}
              >
                {fonter(`About the Founder`, `h3`, true)}
                {fonter(`Join the Sisters Cookies Baking Academy to learn various making tips and tricks directly from your very own`, `h6`, false)}
                {fonter(`Suriani A. Samad`, `body1`, true)}

                <IconButton onClick={executeVideo} className={classes.largeButton}>
                  <PlayCircleOutlineRoundedIcon className={classes.largeIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Parallax>
    </Collapse>
    <Collapse in={playVid}>
      <Card>
        {/* <CardActionArea onClick={pauseVid}>
          <ReactPlayer
            url={[{ src: introURL, type: 'video/mp4' }]}
            width='100%'
            height='100%'
            onEnded={pauseVid}
            playing={playVid}
          />
        </CardActionArea> */}

        <CardActionArea onClick={pauseVid}>
          {introURL != '' && <video controls width="100%">
            <source src={introURL} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>}
        </CardActionArea>
      </Card>

    </Collapse>

  </div>

const Homepage = ({ closeRegPage, regPage, Login, closePwPage, pwPage }) => {
  const auth = getAuth(app);
  const [checked, setChecked] = useState(false);
  const [role, setRole] = useState('')
  const [testimonialList, setTestimonialList] = useState([]);
  const [premiumInfo, setPremiumInfo] = useState({ plan: '', subID: '', orderID: '' })
  const [playVid, setPlayVid] = useState(false)
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("SistersCookies")
  const [page, setPage] = useState()
  const [anime1State, setAnime1State] = useState(false)
  const [anime2State, setAnime2State] = useState(false)
  const [anime3State, setAnime3State] = useState(false)
  const [anime4State, setAnime4State] = useState(false)

  const animeRefs = [...(new Array(4))].map(x => useRef(null));

  const onScroll = () => {
    const scrollWindow = window.scrollY + window.innerHeight
    const top = (i) => animeRefs[i].current.getBoundingClientRect().top
    if (top(2) < scrollWindow) { setAnime3State(true) }
    if (top(0) < scrollWindow) { setAnime1State(true) }
    if (top(1) < scrollWindow) { setAnime2State(true) }
    if (top(3) < scrollWindow) { setAnime4State(true) }
  }

  useLayoutEffect(() => {
    window.addEventListener(`scroll`, onScroll)
    return () => window.removeEventListener(`scroll`, onScroll)

  }, [])

  const [user] = useAuthState(auth)

  const tabFont = (text) => <Box component="div" display="inline" fontFamily="Poppins" fontWeight='h6'   >
    {text}
  </Box>
  const Tabs = () =>
    <StyledTabs variant='scrollable' value={0} scrollButtons="on">
      <div style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}>
        <StyledHomeTab
          label={tabFont('Login')}
          onClick={() => dialogLink(0)} />
      </div>
      <div style={{ marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}>
        <StyledHomeTab
          label={tabFont('Sign Up')}
          onClick={() => dialogLink(1)} /></div>
      <div style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, marginRight: 5, marginTop: 20, marginBottom: 20, backgroundColor: `#390b0b` }}>
        <StyledHomeTab
          label={tabFont('Contact Us')}
          onClick={() => dialogLink(2)} /></div>
    </StyledTabs>


  const handleClose = () => {
    setOpen(false);
    closeRegPage()
    closePwPage()
  };

  var dateInPast = function (firstDate, secondDate) {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  };

  const [paypalKey, setPaypalKey] = useState();
  const [paymentModes, setPaymentModes] = useState();
  const [introURL, setIntroURL] = useState(``)
  const db = getDatabase(app);
  const storage = getStorage(app);

  useEffect(() => {
    const paymentRef = ref(db, 'PaymentModes/');
    onValue(paymentRef, (snapshot) => {
      const mode = snapshot.val();
      const modeArray = []
      for (let id in mode) {
        (id == 'paypalKey') ? setPaypalKey(mode[id]) : modeArray.push(mode[id]);
      }
      setPaymentModes(modeArray)
    });


    getDownloadURL(storageRef(storage, `Videos/Intro.mp4`))
      .then((url) => {
        setIntroURL(url)
      })

    // getDownloadURL(storageRef(storage, `Videos/Intro.mp4`))
    //   .then((intro) => { setIntroURL(intro) })

    user ? setTitle(`Welcome ${user.displayName}!`) : setTitle(`SistersCookies`)


    user && user.getIdTokenResult().then(token => {
      setRole(token.claims.type)
      if (token.claims.type == 'premium') {
        setPremiumInfo({
          plan: token.claims.plan,
          subID: token.claims.subscriptionID,
          orderID: token.claims.orderID,
          startDate: token.claims.startDate,
          endDate: token.claims.endDate
        })
      }
    })


    //Get testimonial listp
    onValue(ref(db, 'Testimonials'), (snapshot) => {
      const testimonial = snapshot.val();
      const testimonialArray = []
      for (let id in testimonial) {
        testimonialArray.push(testimonial[id]);
      }
      setTestimonialList(testimonialArray)
    })


    // ref(db,"Testimonials").on('value', (snapshot) => {
    //   const testimonial = snapshot.val();
    //   const testimonialArray = []
    //   for (let id in testimonial) {
    //     testimonialArray.push(testimonial[id]);
    //   }
    //   setTestimonialList(testimonialArray)
    // })


    setTimeout(() => setChecked(true), 500);

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    var today = new Date();
    if (premiumInfo.endDate != null) {
      if (dateInPast(premiumInfo.endDate, today)) {
        alert('Your subscription has ended')
      }
    }
  }, [])

  //email verification
  if (isSignInWithEmailLink(auth, window.location.href)) {
    var email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    sendSignInLinkToEmail(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem('emailForSignIn');
      })
      .catch((error) => {
        alert(error)
      });
  }

  const classes = useStyles();

  const tabLabel = ["Log In", "Sign Up", "Contact Us"]

  function dialogLink(index) {
    setOpen(true)
    setPage(tabLabel[index])
  }


  const PremiumRow = ({ animeRef, animeState }) =>
    <Box ref={animeRef} mt={3} mx={{ xs: 0, sm: 2, md: 10, lg: 20, xl: 40 }}>
      <Fade in={animeState}
        style={{ transformOrigin: '0 0 0' }}
        {...(animeState ? { timeout: 5000 } : {})}>
        <Card style={{ borderRadius: 20 }}>
          <Toolbar>
            {fonter(`Plan details`, `h5`, true)}
          </Toolbar>
          <CardContent style={{ backgroundColor: '#fdf4ed' }}>
            {fonter(premiumInfo.plan, `h6`, true)}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <Toolbar>
                    {fonter(`Subscription ID`, `body1`, true)}
                  </Toolbar>
                  <Toolbar>
                    {fonter(premiumInfo.subID, `body2`, true)}
                  </Toolbar>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <Toolbar>
                    {fonter(`Order ID`, `body1`, true)}
                  </Toolbar>
                  <Toolbar>
                    {fonter(premiumInfo.orderID, `body2`, true)}
                  </Toolbar>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <Toolbar>
                    {fonter(`Start Date`, `body1`, true)}
                  </Toolbar>
                  <Toolbar>
                    {fonter(premiumInfo.startDate, `body2`, true)}
                  </Toolbar>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <Toolbar>
                    {fonter(`End Date`, `body1`, true)}
                  </Toolbar>
                  <Toolbar>
                    {fonter(premiumInfo.endDate, `body2`, true)}
                  </Toolbar>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fade>
    </Box>


  return (
    <div>
      <Box pb={10} >
        {!user &&
          <AppBar elevation={0} position='absolute' color='transparent'>
            <Box mx={{ xs: 0, sm: 0, md: 10, lg: 20, xl: 50 }}>
              <br />
              {/* Mobile UI */}
              <Hidden xlUp lgUp>
                <Box display="flex" alignItems='center' justifyContent='center'>
                  <IconButton aria-label="Home">
                    <img alt="logo" src={logo} height="200" />
                  </IconButton>
                </Box>
                <Box display="flex" alignItems='center' justifyContent='center'>
                  <Tabs />
                </Box>
              </Hidden>
              {/* Desktop UI */}
              <Hidden mdDown smDown xsDown>
                <Box display="flex" p={1}>
                  <Box flexGrow={1}>
                    <IconButton aria-label="Home">
                      <img alt="logo" src={logo} height="200" />
                    </IconButton>
                  </Box>
                  <Box mt={5}>
                    <Tabs />
                  </Box>
                </Box>
              </Hidden>
            </Box>
          </AppBar>
        }
        <Header checked={checked} title={title} Tabs={Tabs} user={user} />
        <Dialog open={open}
          scroll={'body'}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }} onClose={handleClose}>
          <Collapse in={!pwPage}>
            <Collapse in={!regPage}>
              <Toolbar>
                {fonter(page, `h5`, true)}
              </Toolbar>
            </Collapse>
            <Collapse in={regPage}>
              <Toolbar>
                {fonter(`Sign Up`, `h5`, true)}
              </Toolbar>
            </Collapse>
          </Collapse>
          <Collapse in={pwPage}>
            <Toolbar>
              {fonter(`Password Reset`, `h5`, true)}
            </Toolbar>
          </Collapse>

          {(() => {
            switch (page) {
              case 'Log In':
                return (
                  Login
                )
              case 'Sign Up':
                return (
                  <SignUp />
                )
              default:
                return (
                  <DialogContentText
                    style={{
                      padding: 10,
                      backgroundColor: '#fdf4ed'
                    }}
                  ><ContactUs />
                  </DialogContentText>
                )
            }

          })()}

        </Dialog>

        <InfoRow
          checked={checked} />

        <PremiumClasses freemode={true} />

        {role == 'premium' ?
          <div>
            <PremiumRow
              animeRef={animeRefs[3]}
              animeState={anime4State}
            />
            <div ref={animeRefs[0]}></div>
            <div ref={animeRefs[1]}></div>
          </div>
          :
          <Box>
            <div ref={animeRefs[3]}></div>
            {paymentModes ?
              <PaymentOptions
                animeRef={animeRefs[0]}
                animeState={anime1State}
                paypalKey={paypalKey}
                paymentModes={paymentModes}
                handleClickOpen={() => dialogLink(1)} /> : <div ref={animeRefs[0]}>Loading</div>
            }
            <MasterBakingRow
              animeRefs={animeRefs}
              animeState={anime2State}
              classes={classes} />
          </Box>
        }


        <Testimonials
          animeRef={animeRefs[2]}
          animeState={anime3State}
          classes={classes}
          testimonialDetails={testimonialList} />
        <AboutFounder
          introURL={introURL}
          executeVideo={() => setPlayVid(true)}
          playVid={playVid}
          pauseVid={() => setPlayVid(false)}
          classes={classes} />

        {role != 'premium' &&
          <Promo
            role={role}
            checked={checked}
            executeScroll={() => animeRefs[0].current.scrollIntoView()}
            handleClickOpen={() => dialogLink(1)} />
        }

        <Chefs classes={classes} />

      </Box>
      {!user &&
        <Footer
          loggedIn={false}
          loginButton={() => dialogLink(0)}
          registerButton={() => dialogLink(1)}
          contactUsButton={() => dialogLink(2)}
        />
      }</div>
  )
};

Homepage.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Homepage);



//     