import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import banner from '../assets/img/admin.jpg'
// import { ReactVideo } from "reactjs-media";
import app from '../api/base.js'
import VideocamIcon from '@material-ui/icons/Videocam';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import {
  NativeSelect,
  Dialog, DialogTitle, ListItem, ListItemText, List, DialogContent, DialogContentText, DialogActions, Avatar, Button, Grid,
  AppBar, Toolbar, CardActions, CardContent, Card, CardHeader,
  Box, IconButton, Slide, withWidth, TextField, makeStyles, FormLabel, FormControl, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core';
import { alertbar, Header, fonter } from "../components/TableComponents.js";
import { DataGrid } from '@material-ui/data-grid';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, push } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import '../App.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function useToggle(initialValue = false) {
  const [value, setValue] = React.useState(initialValue);
  const toggle = React.useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    backgroundColor: '#fdf4ed',
    borderRadius: 20
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#192b41',
    justifyContent: 'space-between'
  },
  bottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#192b41'
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardcontent: {
    backgroundColor: 'white',
    padding: 30
  },
  cardactions: {
    marginTop: 20
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  datagrid: {
    selectLabel: ''
  },
  grow: {
    flexGrow: 1,
  },
  pos: {
    marginBottom: 12,
  },
}));

const App = () => {
  const [message, setMessage] = useState({ message: ``, severity: "success" });
  const [alertState, setAlertState] = useState(false);
  const [userList, setUserList] = useState([])
  const [searchList, setSearchList] = useState([])
  const [dataList, setDataList] = useState([])
  const [userRowData, setUserRowData] = useState()
  const [openUser, setOpenUser] = useState(false)
  const [openRecipeInfo, setRecipeInfo] = useToggle()
  const [openUserInfo, setUserInfo] = useToggle()
  const [recipeListItems, setRecipeListItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [pending, setPending] = useState(false)
  const [searchShow, setSearchShow] = useState(false)


  const classes = useStyles();
  const [state, setState] = useState({
    recipeTitle: ""
  })

  function Search() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="#808080" height="18" viewBox="0 -960 960 960" width="18">
        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
      </svg>
    )
  }

  const postMasterData = (value) => {
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: userRowData[columns[1].field], role: roles[value] })
    };
    fetch('https://setrole-px7qpymfyq-uc.a.run.app', requestOptions)
      .then(response => response.json())
      .then(data => {
        alert(data.item)
        window.location.reload();
      });
  }



  //Add Category Function
  const [categoryValue, setCategoryValue] = useState()
  const [recipe, setRecipe] = useState()
  const [video, setVideo] = useState()
  const [image, setImage] = useState()


  const handleCategories = (event) => {
    setCategoryValue(event.target.value);
  };

  //Handle file upload
  const onFileChange = (e) => {
    switch (e.target.name) {
      case 'recipe':
        setRecipe(e.target.files[0])
        break;
      case 'video':
        setVideo(e.target.files[0])
        break;
      case 'image':
        setImage(e.target.files[0])
        break;
    }

  }



  const errorBar = (item) => {
    setMessage({ message: item, severity: "warning" })
    setAlertState(true)
  }

  const addNewRecipe = (e) => {
    e.preventDefault()
    setTimeout(() => { setAlertState(false) }, 1500)
    console.log(image.name)
    console.log(image.name === `${state.recipeTitle}.png`)

    if (state.recipeTitle == ``) { errorBar(`Title is empty`) }
    else if (!categoryValue) { errorBar(`Category is empty`) }
    else if (!image) { errorBar(`No Image Uploaded`) }
    else if (!video) { errorBar(`No Video Uploaded`) }
    else if (!recipe) { errorBar(`No Recipe Uploaded`) }
    // else if (image.name !== `${state.recipeTitle}.png`) {
    //   errorBar(`File name needs to be ${state.recipeTitle}.png`)
    // }
    // else if (video.name !== `${state.recipeTitle}.mp4`) {
    //   errorBar(`File name needs to be ${state.recipeTitle}.mp4`)
    // }
    // else if (recipe.name == `${(state.recipeTitle).replace(/\s/g, "")}.pdf`) {
    //   errorBar(`File name needs to be ${state.recipeTitle}.pdf`)
    // }

    else {
      // alert(`All Good`)

      //   const db = getDatabase(app);
      //   const recipeFileRef = ref(db, 'Recipes').child(recipe.name)
      //   const videoFileRef = ref(db, 'Videos').child(video.name)
      //   const imageFileRef = ref(db, 'Images').child(image.name)
      //   const recipeRef = ref(db, "Recipes");
      //   const recipeList = {
      //     Category: categoryValue,
      //     Name: state.recipeTitle
      //   }
      //   recipeRef.push(recipeList).then(() => {
      //     recipeFileRef.put(recipe)
      //     videoFileRef.put(video)
      //     imageFileRef.put(image)
      //     setMessage({ message: `Successfully added ${state.recipeTitle} into server`, severity: "success" })
      //     setAlertState(true)
      //   }).catch((err) => alert(err))
      // }


      // Assuming you've already initialized your Firebase app
      const db = getDatabase();

      // Create references to the desired paths
      const recipeFileRef = ref(storage, 'Recipes/' + recipe.name)
      const videoFileRef = ref(storage, 'Videos/' + video.name)
      const imageFileRef = ref(storage, 'Images/' + image.name)
      const recipeRef = ref(storage, 'Recipes');

      // Construct the recipe data
      const recipeList = {
        Category: categoryValue,
        Name: state.recipeTitle,
      };

      // Push the recipe data to the database
      push(recipeRef, recipeList)
        .then(() => {
          // Upload files to their respective paths
          uploadBytes(recipeFileRef, recipe);
          uploadBytes(videoFileRef, video);
          uploadBytes(imageFileRef, image);

          // Set a success message (you can handle this part as needed)
          setMessage({
            message: `Successfully added ${state.recipeTitle} to the server`,
            severity: 'success',
          });
          setAlertState(true);
        })
        .catch((err) => {
          // Handle any errors
          alert(err);
        });
    }
  }


  const columns = [
    { field: 'displayName', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'type', headerName: 'Role', flex: 1 },
    { field: 'createdOn', headerName: 'Created On', flex: 1 },
    { field: 'lastSignIn', headerName: 'Last Sign In', flex: 1 },
    { field: 'startDate', headerName: 'Start Date', flex: 1 },
    { field: 'endDate', headerName: 'End Date', flex: 1 },
    { field: 'emailVerified', headerName: 'Email Verified Status', flex: 1 }
  ];
  const db = getDatabase(app);
  useEffect(() => {
    getUsers()
    //Get list of recipes
    const recipeRef = ref(db, 'Recipes/');
    onValue(recipeRef, (snapshot) => {
      const recipes = snapshot.val();
      const recipeArray = []
      for (let id in recipes) {
        const item = recipes[id]
        recipeArray.push(item.Name)
      }
      setRecipeListItems(recipeArray)
    })

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

  }, [])

  function getUsers() {
    fetch('https://getusers-px7qpymfyq-uc.a.run.app', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: 'admin' })
    })
      .then(response => response.json())
      .then(data => {
        const filtered = ([...(new Array(data.item.length))].map((_, i) => {
          var roleType = data.item[i].customClaims == null ? 'free' : data.item[i].customClaims.type
          var startDate = data.item[i].customClaims == null ? null : data.item[i].customClaims.startDate
          var endDate = data.item[i].customClaims == null ? null : data.item[i].customClaims.endDate
          return ({
            'id': data.item[i].uid,
            'displayName': data.item[i].displayName,
            'email': data.item[i].email,
            'emailVerified': data.item[i].emailVerified.toString(),
            'type': roleType,
            'startDate': startDate,
            'endDate': endDate,
            'photo': data.item[i].photoURL,
            'createdOn': data.item[i].metadata.creationTime,
            'lastSignIn': data.item[i].metadata.lastSignInTime
          })
        }))

        setUserList(filtered)
        setSearchList(filtered)
      })
  }

  function handleSearchProduct(value) {
    let trimmed = value.trim()
    let data = searchList.filter(x =>
      x.displayName.toLowerCase().includes(trimmed.toLowerCase()) ||
      x.email.toLowerCase().includes(trimmed.toLowerCase())
    );

    setUserList(data)
    setPending(true)
  }

  function handleSearch() {
    setSearchShow(!searchShow)
  }

  function handleTextChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  const categories = [
    { option: 'Cakes', value: 'Cakes' },
    { option: 'Pastries', value: 'Pastries' },
    { option: 'Breads', value: 'Breads' },
    { option: 'Cookies', value: 'Cookies' }
  ]

  const roles = ['admin', 'premium', 'free']
  const handleRowClick = (data) => {
    setOpenUser(true)
    setUserRowData(data)
  }

  const handleDeleteUser = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ uid: userRowData['id'], user: getAuth().currentUser })
    };
    fetch('https://deleterole-px7qpymfyq-uc.a.run.app', requestOptions)
      .then(response => response.json())
      .then(data => {
        alert(data.item)
        setOpenUser(false)
        setDeleteAlert(false)
        getUsers()
      });
  }

  const [deleteAlert, setDeleteAlert] = useState(false)

  const storage = getStorage(app);

  const deleteItem = () => {
    ref(db, "Recipes").child(selectedItem).remove()
    storageRef(storage, 'Recipes').child(selectedItem + ".pdf").delete()
    storageRef(storage, 'Videos').child(selectedItem + ".mp4").delete()
    storageRef(storage, 'Images').child(selectedItem + ".png").delete()
  }

  return (
    <Box pb={10} style={{ backgroundColor: '#192b41' }} >
      <Header banner={banner} checked={true} title='Administrative Functions' />
      <Box mt={6} mx={{ xs: 0, sm: 2, md: 5, lg: 20, xl: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <CardHeader title={<Box display='flex' height='100%'>
                <Box flexGrow={1}>{fonter(`Manage Recipes`, `h5`, true)}</Box>
                <p> Work In Progress </p>
                <IconButton onClick={setRecipeInfo}>
                  <HelpOutlineRoundedIcon />
                </IconButton>
              </Box>}>
              </CardHeader>
              <CardContent>
                {recipeListItems.length != 0 &&
                  <NativeSelect
                    // defaultValue={roles.indexOf(userRowData[columns[i].field])}
                    onChange={(e) => setSelectedItem(recipeListItems[e.target.value])}
                    inputProps={{
                      name: 'name',
                      id: 'uncontrolled-native',
                    }}
                  >
                    {recipeListItems.map((_, i) => <option key={i} value={i}>{recipeListItems[i]}</option>)}
                  </NativeSelect>}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  onClick={deleteItem}
                  // component="label"
                  // startIcon={recipe ? <DoneOutlineIcon /> : <InsertDriveFileIcon />}
                  style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                  <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                    Delete
                  </Box>
                </Button>
                <Button
                  fullWidth
                  // onClick={addNewRecipe}
                  // startIcon={<PublishIcon />}
                  style={{ padding: '10px', borderRadius: 20, color: 'white', backgroundColor: '#542224', marginBottom: '10px' }}>
                  <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                    Edit
                  </Box>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={video ? 6 : 12}>
            <form>
              <Card className={classes.root}>
                <CardHeader title={<Box display='flex' height='100%'>
                  <Box flexGrow={1}>{fonter(`Add New Recipe`, `h5`, true)}</Box>
                  <IconButton onClick={setRecipeInfo}>
                    <HelpOutlineRoundedIcon />
                  </IconButton>
                </Box>}>
                </CardHeader>
                {alertbar({ message: `If recipe title is Roti Kirai, format is as follows:\nRoti Kirai.mp4, Roti Kirai.png, RotiKirai.pdf`, severity: "info" }, openRecipeInfo)}
                <CardContent className={classes.cardcontent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Recipe Title"
                        name="recipeTitle"
                        value={state.recipeTitle}
                        onChange={handleTextChange}
                        type="text"
                      /></Grid>
                  </Grid>
                  <FormControl style={{ marginTop: 30 }} component="fieldset">
                    <FormLabel component="legend">{fonter(`Categories`, `h6`, true)}</FormLabel>
                    <RadioGroup row value={categoryValue} onChange={handleCategories}>
                      {[...(new Array(categories.length))].map((_, i) =>
                        <FormControlLabel key={categories[i].option} value={categories[i].value} control={<Radio />} label={fonter(categories[i].option, `body1`, false)} />)}
                    </RadioGroup>
                  </FormControl>
                </CardContent>
                {alertbar(message, alertState)}
                <CardActions className={classes.cardactions}>

                  <Button
                    fullWidth
                    component="label"
                    startIcon={video ? <DoneOutlineIcon /> : <VideocamIcon />}
                    style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                    <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                      {video ? video.name : `Upload Video (.MP4)`}
                    </Box>
                    <input
                      type="file"
                      name='video'
                      onChange={onFileChange}
                      hidden
                    />
                  </Button>
                  <Button
                    fullWidth
                    component="label"
                    startIcon={image ? <DoneOutlineIcon /> : <InsertPhotoIcon />}
                    style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                    <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                      {image ? image.name : `Upload Image (.PNG)`}
                    </Box>
                    <input
                      type="file"
                      name='image'
                      onChange={onFileChange}
                      hidden
                    />
                  </Button>

                </CardActions>
                <CardActions>
                  <Button
                    fullWidth
                    component="label"
                    startIcon={recipe ? <DoneOutlineIcon /> : <InsertDriveFileIcon />}
                    style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                    <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                      {recipe ? recipe.name : `Upload Recipe (.PDF)`}
                    </Box>
                    <input
                      type="file"
                      name='recipe'
                      onChange={onFileChange}
                      hidden
                    />
                  </Button>
                  <Button
                    fullWidth
                    onClick={addNewRecipe}
                    startIcon={<PublishIcon />}
                    style={{ padding: '10px', borderRadius: 20, color: 'white', backgroundColor: '#542224', marginBottom: '10px' }}>
                    <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                      Submit entry
                    </Box>
                  </Button>
                </CardActions>
              </Card></form>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* {video &&
              <Card style={{ borderRadius: 20 }}><ReactVideo
                src={URL.createObjectURL(video)}
                poster={image ? URL.createObjectURL(image) : null}
                primaryColor="#542224"
              /></Card>} */}
            {video &&
              <Card style={{ borderRadius: 20 }}>
                <video controls width="100%">
                  <source src={URL.createObjectURL(video)} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video></Card>}
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <Toolbar>
                {fonter(`Users`, `h5`, true)}
                <div className={classes.grow} />
                <IconButton edge="end" style={{ marginTop: 0 }} onClick={setUserInfo}>
                  <HelpOutlineRoundedIcon />
                </IconButton>
              </Toolbar>
              {alertbar({ message: `Click on a row to open more information`, severity: "info" }, openUserInfo)}

              <div className='search-container'>
                <div className='wrap-input'>
                  <input type='search' placeholder='Search by' onChange={(e) => { handleSearchProduct(e.target.value) }} />
                  <span onClick={() => handleSearch()}><Search /></span>
                </div>
              </div>



              <Card style={{ marginBottom: 30 }}>
                <DataGrid
                  autoHeight
                  pageSize={50}
                  rowsPerPageOptions={[5]}
                  rows={userList}
                  columns={columns.slice(0, -4)}
                  onRowClick={(rowData) => handleRowClick(rowData.row)} />
                <Box m={2}>
                </Box>
              </Card>
            </Card>
          </Grid>
        </Grid>

      </Box>
      {userRowData != null &&
        <Dialog
          fullScreen
          open={openUser}
          TransitionComponent={Transition}
          onClose={() => setOpenUser(false)}>

          <AppBar className={classes.appBar}>
            <Toolbar>
              <Avatar edge='end' alt={userRowData[columns[0].field]} src={userRowData.photo} />
              <Box className={classes.dialogTitle}>
                {fonter(userRowData[columns[0].field], `h5`, false)}
              </Box>

              <div className={classes.grow} />

              <IconButton edge="end" color="inherit" onClick={() => setOpenUser(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container
            alignItems='center'
            justifyContent='center'
            style={{ height: `70%` }}>

            <Grid item xs={12} sm={7} lg={6}>
              <List>
                {[...(new Array(columns.length))].map((_, i) =>
                  (i != 0) && (userRowData[columns[i].field]) &&
                  <Card style={{
                    backgroundColor: `#fdf4ed`,
                    borderTopLeftRadius: i == 1 && 30,
                    borderTopRightRadius: i == 1 && 30,
                    borderBottomLeftRadius: i == columns.length - 1 && 30,
                    borderBottomRightRadius: i == columns.length - 1 && 30,
                  }} elevation={10}>
                    <ListItem>
                      <ListItemText primary={fonter(columns[i].headerName, `h6`, true)} secondary=
                        {i != 2 ? fonter(userRowData[columns[i].field], `body1`, false) :
                          <NativeSelect
                            defaultValue={roles.indexOf(userRowData[columns[i].field])}
                            onChange={(e) => postMasterData(e.target.value)}
                            inputProps={{
                              name: 'name',
                              id: 'uncontrolled-native',
                            }}
                          >

                            {roles.map((_, i) => <option key={i} value={i}>{roles[i]}</option>)}
                          </NativeSelect>
                        } />
                    </ListItem>
                  </Card>)}
              </List>
            </Grid>
          </Grid>

          <AppBar position="fixed" color="primary" className={classes.bottomBar}>
            <Toolbar>
              <div className={classes.grow} />
              <IconButton edge="end" color="inherit" onClick={() => setDeleteAlert(true)}>
                <DeleteIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      }
      <Dialog
        open={deleteAlert}
        onClose={() => setDeleteAlert(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Users whose accounts has been deleted will need to register again to log in
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAlert(false)} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteUser} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
};

App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(App);