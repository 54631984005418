import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import { withWidth, CardHeader, Grid, Card, CardContent, Button, TextField, Fade, Box } from '@material-ui/core';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import IconButton from '@material-ui/core/IconButton';
import banner from '../assets/img/contact-us-banner.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { forms, Header, fonter } from '../components/TableComponents';
import { AuthContext } from '../components/Auth'
import { textfieldStyles } from '../components/styles';
import emailjs from '@emailjs/browser'

const useStyles = makeStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fef1e8',
    borderRadius: 20
  },
  root: {
    padding: 10,
    backgroundColor: '#fdf4ed',
    borderRadius: 20
  },
  cardcontent: {
    backgroundColor: 'white',
    padding: 30,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  }
});



const App = () => {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();
  const textClass = textfieldStyles()

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_k2vsz18', 'template_clx5brr', e.target, 'user_zKZrUq4Tl3ZPsEHvU6HFg')
      .then((result) => {
        alert(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }


  useEffect(() => {
    setChecked(true)
  }, [])


  const { currentUser } = useContext(AuthContext);

  return (

    <Box>
      {currentUser && <Header banner={banner} checked={checked} title='Contact Us' />}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ minHeight: "70vh" }}
      >
        <Grid item>
          <Grid container spacing={2}>
            <Grid item sm={currentUser ? 6 : 12}>
              <Fade in={checked}
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 1500 } : {})}>

                <Card className={classes.root}>
                  <CardHeader title={fonter(`Let’s Be Acquaintances`, `h4`, true)} />
                  <CardContent className={classes.cardcontent}>
                    {fonter(`We will help you master the most delicious baking recipes.`, `body1`, true)}
                    <Box display='flex' p={3}>
                      <Box flexGrow={1}>
                        <Button startIcon={<PhoneIphoneOutlinedIcon />} href="tel:+65 9711 1350">
                          {fonter(`+65 9711 1350`, `body1`, true)}
                        </Button>
                      </Box>
                      <Button startIcon={<EmailOutlinedIcon />} href="mailto:sisterscookies@yahoo.com.sg">
                        {fonter(`sisterscookies@yahoo.com.sg`, `body1`, true)}
                      </Button>
                    </Box>
                    {fonter(` Follow us on the following platforms:`, `h5`, true)}
                    <IconButton aria-label="facebook" href="https://www.facebook.com/sisterscookies/">
                      <FacebookIcon />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/sisterscookies.bakingacademy/" aria-label="instagram">
                      <InstagramIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
            <Grid item sm={currentUser ? 6 : 12}>
              <Fade
                in={checked}
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 2000 } : {})}
              >
                <Card className={classes.root}>
                  <form onSubmit={sendEmail}>
                    <CardHeader title={<Box display='flex' height='100%'>
                      <Box flexGrow={1} mb={2}>{fonter(`We’d love to hear from you`, `h5`, true)}</Box>
                      <Button
                        type='submit'
                        endIcon={<SendIcon />}
                        style={{ borderRadius: 20, backgroundColor: '#542234', color: 'white', paddingRight: '20px', marginBottom: '12px' }}>
                        <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                          SEND
                        </Box>
                      </Button>
                    </Box>} />
                    <CardContent className={classes.cardcontent}>

                      <Grid container spacing={2}>
                        {[...(new Array(forms.length))].map((_, i) => {
                          return (
                            <Grid key={forms[i].label} item xs={(i == 3) ? 12 : 6}>
                              <TextField
                                key={forms[i].label}
                                variant="outlined"
                                fullWidth
                                multiline={(i == 3) ? true : false}
                                rows={(i == 3) ? 4 : 1}
                                className={textClass.root}
                                InputLabelProps={{
                                  classes: {
                                    root: textClass.cssLabel
                                  }
                                }}
                                InputProps={{
                                  classes: {
                                    root: textClass.input,
                                  }
                                }}
                                name={forms[i].name}
                                label={forms[i].label}
                                type={forms[i].type} />
                            </Grid>

                          )
                        })}
                      </Grid>


                    </CardContent>
                  </form>
                </Card>
              </Fade >
            </Grid >
          </Grid >
        </Grid >
      </Grid >


    </Box>
  )
};

App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(App);