import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyCDY47JI2-SZMTtWX6sDpMbbdg6c2qQ964",
  authDomain: "sisters-cookies.firebaseapp.com",
  databaseURL: "https://sisters-cookies-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sisters-cookies",
  storageBucket: "sisters-cookies.appspot.com",
  messagingSenderId: "591612214966",
  appId: "1:591612214966:web:94ea793b7710f4ce45f017"
}


// Your web app's Firebase configuration
const app = initializeApp(firebaseConfig)
export default app;