import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Hidden, CardContent, Button, CardHeader, Card, Box, Fade, TextField, Grid, withWidth, makeStyles } from '@material-ui/core';
// import { ReactVideo } from "reactjs-media";
import emailjs from '@emailjs/browser'
import { forms, Header, fonter, InfoRow } from '../components/TableComponents';
import GetAppIcon from '@material-ui/icons/GetApp';
import app from '../api/base.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import { textfieldStyles } from '../components/styles';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";



const useStyles = makeStyles({
  details: {
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fef1e8',
    borderRadius: 20,
    padding: 10
  },

  cover: {
    height: 500,
    width: 500
  }
});

function sendEmail(e) {
  e.preventDefault();
  emailjs.sendForm('service_k2vsz18', 'template_clx5brr', e.target, 'user_zKZrUq4Tl3ZPsEHvU6HFg')
    .then((result) => {
      alert(result.text);
    }, (error) => {
      console.log(error.text);
    });
}



const RecipeInfo = ({ name, link }) => {
  const [checked, setChecked] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState('')
  const [downloadURL, setDownloadURL] = useState('')
  const [videoURL, setVideoURL] = useState('')
  const [imageURL, setImageURL] = useState('')
  const shortenedName = name.split(' ').join('')
  const classes = useStyles();
  const textClass = textfieldStyles()

  const [user] = useAuthState(getAuth())

  const auth = getAuth(app);
  const storage = getStorage(app);

  useEffect(() => {
    setChecked(true)


    onAuthStateChanged(auth, user => {
      if (user) {

        getDownloadURL(storageRef(storage, `Recipes/${shortenedName}.pdf`))
          .then((url) => {
            setFirebaseURL(url)
            setDownloadURL(url)
          })

        getDownloadURL(storageRef(storage, `Videos/${name}.mp4`))
          .then((video) => {
            setVideoURL(video)
          })

        getDownloadURL(storageRef(storage, `Images/${name}.png`))
          .then((image) => {
            setImageURL(image)
          })
      }
    })




  }, [])
  return (
    <Box>
      {/* Header */}
      <Header banner={imageURL} checked={checked} title={name} />
      <Box paddingTop={10} mx={{ xs: 0, sm: 2, md: 5, lg: 20, xl: 20 }}>

        {/* Video */}
        {/* {videoURL != '' &&
          <ReactVideo
            src={videoURL}
            poster={imageURL}
            primaryColor="#542224"
          />} */}

        {videoURL != '' &&
          <video controls width="100%">
            <source src={videoURL}type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>}

        {/* Recipe */}
        <Fade in={checked}
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 500 } : {})}>
          <Card className={classes.details}>

            <Grid container>
              <Grid item xs={12} sm={12} lg={7} >
                <CardHeader style={{ color: '#542224' }} title={fonter(`Recipe`, `h4`, true)} />
                <CardContent style={{ width: '95%', height: '100%' }}>
                  {firebaseURL != '' &&
                    <iframe src={firebaseURL} width='100%' height={600} />
                  }
                </CardContent>
              </Grid>

              <Grid item xs={12} sm={12} lg={5} style={{ backgroundColor: 'white', borderRadius: 20, padding: 10 }} >
                <Box mx={2} display='flex' height='100%' flexDirection='column'>
                  <Hidden only='xs'>
                    <Box flexGrow={1}>
                      <InfoRow checked={checked} value={12} />
                    </Box>
                  </Hidden>
                  <Box>
                    <Button
                      aria-label="save recipe"
                      href={downloadURL}
                      endIcon={<GetAppIcon style={{ color: '#542224' }} />}
                      download={shortenedName + '.docx'}
                      fullWidth
                      style={{ padding: '10px', borderRadius: 20, backgroundColor: '#542234', color: 'white', marginBottom: '10px' }}>
                      <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                        Download Recipe
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Card>
        </Fade>


        {/* Feedback Form */}
        <Card className={classes.details}>
          <form onSubmit={sendEmail}>
            <CardHeader title={fonter(`Feedback form`, `h5`, true)} />
            <CardContent>

              <Grid container spacing={2}>
                {[...(new Array(forms.length))].map((_, i) => {
                  var value = null;
                  switch (i) {
                    case 0:
                      value = user.displayName;
                      break;
                    case 1:
                      value = user.email;
                      break;
                    case 2:
                      value = name;
                      break;
                    default:
                      value = null;
                      break;
                  }

                  return (
                    <Grid key={forms[i].label} item xs={(i == 3) ? 12 : 6}>
                      <TextField
                        key={forms[i].label}
                        variant="outlined"
                        fullWidth
                        multiline={(i == 3) ? true : false}
                        rows={(i == 3) ? 4 : 1}
                        className={textClass.root}
                        InputLabelProps={{
                          classes: {
                            root: textClass.cssLabel
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: textClass.input,
                          }
                        }}
                        value={value}
                        name={forms[i].name}
                        label={forms[i].label}
                        type={forms[i].type} />
                    </Grid>

                  )
                })}
              </Grid>


            </CardContent>
            <Box textAlign='center'
              justifyContent="center"
              alignItems="center" >
              <Button
                type='submit'
                style={{ padding: '10px', borderRadius: 20, backgroundColor: '#542234', color: 'white', marginBottom: '10px' }}>
                <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                  submit
                </Box>
              </Button>
            </Box>
          </form>
        </Card>
      </Box>


    </Box >
  )
};
RecipeInfo.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(RecipeInfo);